import { ButtonMain } from "components/ui/atoms/ButtonMain";
import Select from "components/ui/atoms/Select";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { DealflowService } from "services/dealflow-service";
import FormsService from "services/forms-service";

const DealShareForm = ({ questionnaire }) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const [deals, setDeals] = useState([]);
  const [selectedDeals, setSelectedDeals] = useState([]);

  const shareQuestionnaire = async () => {
    try {
      await Promise.all(
        selectedDeals.map(async (deal) => FormsService.sendQuestionnarieToDeal(questionnaire._id, deal.id))
      );
      setSelectedDeals([]);
      addToast(t("form_sent_successfully"), { appearance: "success", autoDismiss: true });
    } catch (e) {
      addToast(t("error_occurred_sending_form"), { appearance: "error", autoDismiss: true });
    }
  };

  const getDealflow = () =>
    DealflowService.getDealflow({
      rowsPerPage: 999999,
      pool: "true",
      populate: "false",
      boards: "false",
      milestones: "false",
      files: "false",
      reminders: "false",
      archived: "false",
    })
      .then((result) => {
        const { dealflow } = result.data.data;
        setDeals(dealflow);
      })
      .catch(() => {
        addToast(t("error_occurred_retrieving_dealflow"), {
          appearance: "error",
          autoDismiss: true,
        });
      })
      .finally(() => {});

  useEffect(() => {
    getDealflow();
  }, []);

  return (
    <>
      <div className="mt-4">
        <Select
          multiSelect
          label={t("startups_to_send")}
          placeholder={t("select_deals")}
          initialValues={selectedDeals}
          onSelect={(value) => setSelectedDeals(value)}
          required={true}
          name={"deals"}
          isClearable={true}
          items={deals ? deals.map((deal) => ({ id: deal._id, value: deal.name })) : []}
        />
      </div>
      <div className="mt-4">
        <ButtonMain text={t("send")} onClick={shareQuestionnaire} />
      </div>
    </>
  );
};

export default DealShareForm;
