import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Loader } from "components/ui/molecules/Loader";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { isEmpty, isEqual } from "lodash";
import { Pagination } from "components/ui/atoms/Pagination";
import { RoutesLinks } from "components/routes-links";
import { DataGrid } from "components/ui/objects/DataGrid";
import { FaTrash, FaTimes, FaCheck, FaEye, FaRegQuestionCircle, FaFilePdf } from "react-icons/fa";
import moment from "moment";
import { useSwal } from "hooks/useSwal";
import { Card } from "../../../../ui/atoms/Card";
import Avatar from "react-avatar";
import useUser from "../../../../../hooks/useUser";
import { DealflowService } from "../../../../../services/dealflow-service";
import Config from "config";
import { EventsStartupsFilters } from "./EventsStartupsFilters";
import { EvaluationsMatrix } from "components/evaluations/EvaluationsMatrix";
import { AddStartupsToEventSection } from "./AddStartupsToEventSection";
import SuggestedStep from "components/ui/molecules/SuggestedStep";
import Utils from "utils/utils";
import EvaluationProcessesManagerService from "services/evaluation-processes-manager-service";
import ReportsService from "services/reports-service";
import { countAppliedFilters } from "utils/countAppliedFilters";
import { useLocation, useNavigate } from "react-router-dom";

export const EventStartups = forwardRef(
  (
    {
      orderBy,
      setShowFilterStartups,
      evaluations,
      event,
      setShowStartupsButton,
      showFilters,
      setShowFilters,
      setFiltersCount,
    },
    ref
  ) => {
    const navigate = useNavigate();

    const location = useLocation();

    const { t } = useTranslation();
    const { addToast } = useToasts();
    const { confirm } = useSwal();
    const { user, reloadUserInfo, userLanguage } = useUser();
    const [startups, setStartups] = useState([]);
    const [sort, setSort] = useState("-createdAt");
    const [loading, setLoading] = useState(true);
    const [loadingStartupsFromPool, setLoadingStartupsFromPool] = useState(true);
    const [loadingJudges, setLoadingJudges] = useState(true);
    const [showAddStartupSection, setShowAddStartupSection] = useState(false);
    const [filters, setFilters] = useState({});
    const [startupsFromPool, setStartupsFromPool] = useState([]);
    const [judges, setJudges] = useState([]);

    const historyState = location.state;

    const [pagination, setPagination] = useState(
      historyState && historyState?.page && historyState?.rowsPerPage
        ? historyState
        : {
            page: 0,
            rowsPerPage: 10,
            totalPages: 0,
            totalDocs: 0,
          }
    );

    const handleSetFilters = (_filters) => {
      setFilters(_filters);
      setFiltersCount(countAppliedFilters(_filters));
    };

    const getJudges = () => {
      setLoadingJudges(true);
      return EvaluationProcessesManagerService.getJudgesFromEvaluationProcess(event._id, {
        rowsPerPage: 9999,
      })
        .then((result) => {
          setJudges(result.data.data.judges);
        })
        .catch((error) => {
          addToast(t("error_occurred_retrieving_judges"), {
            appearance: "error",
            autoDismiss: true,
          });
          console.error(error);
        })
        .finally(() => {
          setLoadingJudges(false);
        });
    };

    const getStartups = async () => {
      try {
        setLoading(true);

        const result = await EvaluationProcessesManagerService.getDealflowFromEvaluationProcess(event._id, {
          page: pagination.page,
          rowsPerPage: pagination.rowsPerPage,
          sortBy: orderBy || sort,
          ...filters,
        });
        if (!result.data.data.startups.length) {
          // Comprueba que el error por filtro de jueces sea solo por los jueces y no por una combinación de diferentes filtros
          if ("assigned_judge" in filters && Object.keys(filters).length === 1) {
            addToast(t("judge_not_assigned_to_startup"), {
              appearance: "error",
              autoDismiss: true,
            });
          } else {
            // addToast(t("filtered_startups_not_found"), {
            //   appearance: "error",
            //   autoDismiss: true,
            // });
          }
          // Si no se han encontrado startups con los filtros no se aplican
        } else {
          setStartups(result.data.data.startups);
        }

        if (isEmpty(filters)) {
          setShowFilterStartups(!!result.data.data.startups.length);
        } else {
          setShowFilterStartups(true);
        }
        // setJudges(result.data.data.judges)
        setShowStartupsButton(!!result.data.data.startups.length);
        if (result.data.data.pagination) {
          const newPagination = {
            ...pagination,

            page:
              result.data.data.pagination.current >= result.data.data.pagination.pages
                ? result.data.data.pagination.pages - 1
                : result.data.data.pagination.current - 1,
            totalPages: result.data.data.pagination.pages,
            totalDocs: result.data.data.pagination.totalDocs,
          };

          if (!isEqual(newPagination, pagination)) {
            setPagination(newPagination);
          }
        }
      } catch (error) {
        addToast(t("error_occurred_retrieving_startups"), {
          appearance: "error",
          autoDismiss: true,
        });
      } finally {
        setLoading(false);
      }
    };

    const updateStartup = async (startupId, discarded) => {
      try {
        setLoading(true);
        await EvaluationProcessesManagerService.updateDealFromEvaluationProcess(event._id, startupId, {
          discarded,
        });
        getStartups();
        addToast(t(discarded ? "startup_discarded_successfully" : "startup_admitted_successfully"), {
          appearance: "success",
          autoDismiss: true,
        });
      } catch (error) {
        addToast(
          error.response && error.response.data.msg
            ? error.response.data.msg
            : t(discarded ? "error_occurred_discarding_startup" : "error_occurred_admitting_startup"),
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      } finally {
        setLoading(false);
      }
    };

    const deleteStartup = (startupId) => {
      confirm({
        text: t("delete_startup_from_event_description"),
        icon: "error",
      }).then(async (isConfirmed) => {
        if (isConfirmed) {
          try {
            await EvaluationProcessesManagerService.deleteStartupFromEvaluationProcess(event._id, startupId);
            getStartups();
            addToast(t("startup_deleted_from_event_successfully"), {
              appearance: "success",
              autoDismiss: true,
            });
          } catch (error) {
            addToast(
              error.response && error.response.data.msg
                ? error.response.data.msg
                : t("error_occurred_deleting_startup_from_event"),
              {
                appearance: "error",
                autoDismiss: true,
              }
            );
          }
        }
      });
    };

    const downloadEvaluationsReport = async (rowData) => {
      try {
        setLoading(true);
        await ReportsService.generateDealEvaluationsReport(rowData.id, event._id);
      } catch (error) {
        addToast(t(error?.response?.data?.msg_key || "error_occurred_downloading_evaluation_report"), {
          appearance: "error",
          autoDismiss: true,
        });
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    const addPoolStartup = (dealflow, selectedJudges) => {
      setLoading(true);
      return EvaluationProcessesManagerService.addStartupToEvaluationProcess(event._id, {
        dealflow: dealflow._id,
        judges: selectedJudges.map((j) => j.id),
      })
        .then(() => getStartups())
        .then(() => {
          addToast(t("startup_added_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
          reloadUserInfo();
        })
        .catch((error) => {
          addToast(t(error?.response?.data?.msg_key || "error_adding_startup"), {
            appearance: "error",
            autoDismiss: true,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const getStartupsFromPool = () => {
      setLoadingStartupsFromPool(true);
      DealflowService.getDealflow({
        rowsPerPage: 999999,
        pool: "true",
        reminders: "false",
        archived: "false",
        events: {
          event: {
            $ne: event._id,
          },
        },
      })
        .then((response) => {
          setStartupsFromPool(response.data.data.dealflow);
        })
        .catch(() => {
          addToast(t("error_occurred"), {
            appearance: "error",
            autoDismiss: true,
          });
        })
        .finally(() => {
          setLoadingStartupsFromPool(false);
        });
    };

    useEffect(() => {
      if (event) {
        getStartupsFromPool();
        getJudges();
      }
    }, [event]);

    useEffect(() => {
      if (event) {
        getStartups();
      }
    }, [event, pagination.page, pagination.rowsPerPage, filters, orderBy]);

    const getActions = () => {
      const actions = [
        {
          id: "evaluation-report",
          tip: t("evaluation_report_available"),
          buttonProps: {
            iconComponent: <FaFilePdf className="inline" />,
            width: "12",
            horizontal: "1",
            vertical: "1",
            verticalMargin: "0",
            horizontalMargin: "0",
          },
          show: (row) => row.evaluations,
          onClick: (rowData) => downloadEvaluationsReport(rowData),
        },
        {
          id: "admit",
          tip: t("admit"),
          buttonProps: {
            iconComponent: <FaCheck className="inline" />,
            textColor: "green",
            width: "12",
            horizontal: "1",
            vertical: "1",
            verticalMargin: "0",
            horizontalMargin: "0",
          },
          show: (row) => row.discarded && !event.archived,
          onClick: (rowData) => {
            updateStartup(rowData._id, !rowData.discarded);
          },
        },
        {
          id: "discard",
          tip: t("discard"),
          buttonProps: {
            iconComponent: <FaTimes className="inline" />,
            textColor: "red",
            width: "12",
            horizontal: "1",
            vertical: "1",
            verticalMargin: "0",
            horizontalMargin: "0",
          },
          show: (row) => !row.discarded && !event.archived,
          onClick: (rowData) => {
            updateStartup(rowData._id, !rowData.discarded);
          },
        },
        {
          id: "delete",
          tip: t("delete"),
          buttonProps: {
            iconComponent: <FaTrash className="inline" />,
            textColor: "red",
            width: "12",
            horizontal: "1",
            vertical: "1",
            verticalMargin: "0",
            horizontalMargin: "0",
          },
          show: () => !event.archived,
          onClick: (rowData) => {
            deleteStartup(rowData._id);
          },
        },
      ];

      actions.unshift({
        id: "details",
        tip: t("details"),
        buttonProps: {
          iconComponent: <FaEye className="inline" />,
          width: "12",
          horizontal: "1",
          vertical: "1",
          verticalMargin: "0",
          horizontalMargin: "0",
        },
        onClick: (rowData) => {
          navigate(`${RoutesLinks.EVALUATION_PROCESSES_LINK}${event._id}/startups/${rowData._id}`, {
            pagination,
            from: location.pathname,
          });
        },
      });

      return actions;
    };

    const [showSuggestedStep, setShowSuggestedStep] = useState(false);

    useImperativeHandle(ref, () => ({
      addStartup: () => {
        if (
          !!user?.onboarding_tasks.includes("add_startup_to_evaluation_process") ||
          !!user?.investor?.onboarding_tasks?.includes("add_startup_to_evaluation_process") ||
          Utils.checkIfPreviousStepsArCompleted(
            user?.onboarding_tasks,
            user?.investor?.onboarding_tasks,
            "add_startup_to_evaluation_process"
          )
        ) {
          setShowAddStartupSection(true);
        } else {
          setShowSuggestedStep(true);
        }
      },
    }));

    return (
      <>
        {showSuggestedStep && (
          <SuggestedStep
            action={"add_startup_to_evaluation_process"}
            showSuggestedStep={!!showSuggestedStep}
            setShowSuggestedStep={setShowSuggestedStep}
            continueAction={() => {
              setShowSuggestedStep(false);
              setShowAddStartupSection(true);
            }}
            continueText={t("add_startup")}
          />
        )}
        {showFilters && (
          <EventsStartupsFilters
            onClose={() => setShowFilters(false)}
            onFilter={handleSetFilters}
            filters={filters}
            judges={judges.map((judge) => ({ id: judge._id, value: judge.judge }))}
          />
        )}

        {showAddStartupSection && (
          <AddStartupsToEventSection
            judges={judges.map((j) => ({ id: j._id, value: j.judge }))}
            startupsFromPool={startupsFromPool}
            onClose={() => setShowAddStartupSection(false)}
            onSubmit={(startup, selectedJudges) => addPoolStartup(startup, selectedJudges)}
          />
        )}

        {startups.length === 0 && !loading && (
          <div className="my-10">
            <img
              src={`${Config.GUIDE_STEPS_BACKGROUND_IMAGES_BUCKET}evaluation_process_startups_${userLanguage}.png`}
              className="w-auto"
            />
          </div>
        )}

        {startups.length !== 0 && (
          <>
            {evaluations && evaluations.length !== 0 && (
              <div className="my-6">
                <Card>
                  <EvaluationsMatrix
                    scaleToTen={
                      event.score_card?.scale === "scale_to_ten" || event.score_card?.scale === "traffic_light"
                    }
                    evaluations={evaluations}
                    loading={false}
                  />
                </Card>
              </div>
            )}
            <div className="mt-6">
              <Card>
                <DataGrid
                  compact={true}
                  bordered={true}
                  data={startups.map((startup) => ({
                    ...startup,
                    name: startup.name ? startup.name : t("startup_name_not_provided"),
                    id: startup._id,
                  }))}
                  headers={[
                    {
                      key: "name",
                      title: t("startup"),
                      render: (key, startup) => (
                        <>
                          <Avatar
                            src={startup.logo}
                            size="30"
                            round={true}
                            color="#e0e6f2"
                            fgColor="#4d70b3"
                            alt={startup.name}
                          />
                          <span className="ml-2">{startup.name}</span>
                        </>
                      ),
                    },
                    {
                      key: "status",
                      title: (
                        <>
                          {t("status")}
                          <span data-tip={t("event_startup_status_description")} data-multiline={true}>
                            <FaRegQuestionCircle className="inline-block ml-1" />
                          </span>
                        </>
                      ),
                      render: (key, startup) => (
                        <span className={startup.discarded ? "text-red" : "text-green"}>
                          {t(startup.discarded ? "discarded" : "admitted")}
                        </span>
                      ),
                    },
                    {
                      key: "evaluations",
                      title: (
                        <>
                          {t("evaluations")}
                          <span data-tip={t("startup_without_assigned_judge")} data-multiline={true}>
                            <FaRegQuestionCircle className="inline-block ml-1" />
                          </span>
                        </>
                      ),
                      render: (key, startup) => <span>{`${startup.evaluations}/${startup.judges}`}</span>,
                    },
                    {
                      key: "avg",
                      title: <>{t("avg")}</>,
                      render: (key, startup) => (
                        <span>
                          {startup.avg !== null && startup.avg !== undefined
                            ? Math.round((startup.avg + Number.EPSILON) * 100) / 100
                            : "-"}
                        </span>
                      ),
                    },
                    {
                      key: "createdAt",
                      title: t("application_date"),
                      render: (key, startup) => moment(startup.date).format("YYYY-MM-DD HH:mm"),
                    },
                  ]}
                  actions={getActions()}
                />

                <div>
                  <Pagination
                    paginateOptions={[10, 20, 30]}
                    currentPage={pagination.page}
                    setCurrentPage={(page) => {
                      setPagination({
                        ...pagination,
                        page,
                      });
                    }}
                    perPage={pagination.rowsPerPage}
                    pages={pagination.totalPages}
                    setPerPage={(value) => {
                      setPagination({
                        ...pagination,
                        page: 0,
                        rowsPerPage: value[0].id,
                      });
                    }}
                  />
                </div>
              </Card>
            </div>
          </>
        )}

        {(loading || loadingJudges || loadingStartupsFromPool) && <Loader />}
      </>
    );
  }
);
