import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Autocomplete } from "components/ui/atoms/Autocomplete";
import { useToasts } from "react-toast-notifications";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { SidePanel } from "components/ui/objects/SidePanel";
import Select from "components/ui/atoms/Select";

/**
 * @param onSubmit {(type: 'startup' | 'questionnaire' | 'questionnaire_import' | 'pool', data: { application?: any, questionnaireId?:any }) => Promise<void>}
 * @param onFinishImport {() => void}
 * @param onClose
 * @param questionnaire
 * @param showProject
 * @param showQuestionnaire
 * @param showManualQuestionnaireSelector
 * @returns {JSX.Element}
 * @constructor
 */
export const AddStartupsToEventSection = ({ onSubmit, onClose, startupsFromPool, judges = [] }) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [selectedStartup, setSelectedStartup] = useState(undefined);
  const [selectedJudges, setSelectedJudges] = useState([]);

  const close = () => {
    onClose && onClose();
  };

  const onError = () => {
    addToast(t("form_field_error"), { appearance: "error", autoDismiss: true });
  };

  const onSubmitFromPool = () => {
    onSubmit(selectedStartup, selectedJudges).finally(() => close());
  };

  const _onSubmit = (values) => {
    if (!selectedStartup) {
      onError();
      setSelectedStartup(null);
    } else {
      onSubmitFromPool();
    }
  };

  const onSelectPoolStartup = (selected) => {
    setSelectedStartup({
      _id: selected._id,
      name: selected.name,
      logo: selected.logo,
    });
  };

  console.log(startupsFromPool);

  return (
    <SidePanel width={"1/4"} onClose={onClose} title={t("add_startup")}>
      <div style={{ height: "95%", maxHeight: "85vh" }} className="h-86 flex flex-col max-h-full">
        <div className={"flex-1 p-2"}>
          <Autocomplete
            name="startup"
            placeholder={t("search_startup")}
            isClearable={false}
            label={t("add_startup_from_pool_to_event_explanation")}
            required={true}
            options={startupsFromPool ?? []}
            error={selectedStartup !== undefined && selectedStartup === null ? { message: t("required_field") } : null}
            onSelect={onSelectPoolStartup}
          />
          {selectedStartup && (
            <>
              <div className="w-full px-1 text-xs mt-2 mb-4 font-semibold">
                {t("would_you_like_to_add_startup_to_judges")}
              </div>
              <Select
                name="judges"
                label={t("select_judges")}
                multiSelect={true}
                required={false}
                placeholder={t("choose_multiple_options")}
                initialValues={selectedJudges.length ? selectedJudges : []}
                onSelect={(value) => setSelectedJudges(value)}
                items={judges}
                isClearable={true}
              />
            </>
          )}
        </div>

        <div className={`flex justify-end mt-4 border-t pt-2 border-separator`}>
          <ButtonMain text={t("add_startup")} onClick={() => _onSubmit()} />
        </div>
      </div>
    </SidePanel>
  );
};
